html, body {
  height: 100%;
  margin: 0;
  padding: 0; }

body {
  /* Margin bottom by footer height */ }

body {
  background: #F5F5F5; }

.navbar {
  border-radius: 0; }

.navbar-default {
  background: #4CAF50;
  border-color: #429845; }
  .navbar-default .navbar-brand {
    color: #fff; }
    .navbar-default .navbar-brand:hover {
      color: #e6e6e6;
      background-color: transparent; }
  .navbar-default .navbar-nav > li > a {
    color: #fff; }
    .navbar-default .navbar-nav > li > a:hover {
      color: #333;
      background: transparent; }
  .navbar-default .navbar-nav > .active > a,
  .navbar-default .navbar-nav > .active > a:focus,
  .navbar-default .navbar-nav > .active > a:hover {
    color: #e6e6e6;
    background: #429845; }
  .navbar-default .navbar-nav > .open > a,
  .navbar-default .navbar-nav > .open > a:focus,
  .navbar-default .navbar-nav > .open > a:hover {
    color: #e6e6e6;
    background: #429845; }

.footer {
  background: #4CAF50;
  color: whitesmoke; }
  .footer .copyright {
    border-top: 1px solid #77BB7B; }
  .footer li a {
    color: #fff; }

.form-signin {
  max-width: 500px;
  padding: 15px;
  margin: 0 auto; }
  .form-signin .input-group {
    margin-bottom: 10px; }
  .form-signin .signup-info {
    padding-top: 10px; }

.social {
  color: #fff; }
  .social:focus {
    color: #fff; }
  .social.facebook {
    background-color: #4863ae;
    border-color: #4863ae;
    color: #fff; }
    .social.facebook:hover {
      color: #fff;
      background-color: #2871aa;
      border-color: #2871aa; }
  .social.google {
    color: #fff;
    background-color: #dd4b39;
    border-color: #dd4b39; }
    .social.google:hover {
      color: #fff;
      background-color: #e47365;
      border-color: #e47365; }

.social-buttons {
  margin-top: 30px; }

.panel-menu .panel-heading {
  text-transform: uppercase; }

.panel-menu .panel-body {
  padding: 0; }
  .panel-menu .panel-body a {
    display: block;
    padding: 10px 15px;
    background-color: #ffffff;
    border-bottom: 1px solid #ddd; }
    .panel-menu .panel-body a:hover {
      text-decoration: none; }
    .panel-menu .panel-body a:last-child {
      border: none; }

.profile-row {
  margin-bottom: 10px; }
  .profile-row label {
    display: block; }

ul#profile-navigation li > a {
  border-radius: 0; }

.is-visited span {
  font-weight: bold; }

#profile-favorite a i:before {
  content: "\f08a"; }

#profile-favorite a:hover i:before {
  content: "\f004"; }

#profile-visited a.set-visited-place i:before {
  content: "\f097"; }

#profile-visited a.set-visited-place:hover i:before {
  content: "\f02e"; }

.profile-stats {
  margin: 20px 0; }
  .profile-stats ul li div {
    margin-right: 15px;
    padding: 10px;
    border: 1px solid #d9d9d9; }
    .profile-stats ul li div .badge {
      float: right; }
  .profile-stats ul li:last-child div {
    margin-right: 0; }

.avatar {
  margin-top: 30px; }
  .avatar img {
    float: left; }
  .avatar .caption {
    font-size: 20px;
    float: left;
    text-align: left;
    padding-left: 10px; }

.last-seen {
  font-size: 13px;
  color: #c2c2c2; }

.lost-password {
  margin-top: 20px; }

.top-list .place {
  margin-bottom: 15px; }
  .top-list .place h3 {
    margin-top: 0;
    font-size: 18px; }
  .top-list .place .thumbnail .caption {
    margin-top: 10px;
    text-align: center;
    padding: 0;
    font-size: 10px; }
  .top-list .place p {
    padding: 0;
    margin: 0;
    font-size: 13px; }

.row.information {
  max-height: 520px;
  overflow: hidden; }

.place h3 {
  margin-top: 4px;
  font-size: 18px;
  color: #337ab7; }
  .place h3 a {
    font-size: 18px;
    color: #337ab7; }

.place .location {
  font-size: 16px;
  color: #77acd9; }
  .place .location a {
    font-size: 16px;
    color: #77acd9; }

.place .category {
  font-size: 16px;
  color: #77acd9; }
  .place .category a {
    font-size: 16px;
    color: #77acd9; }

.users {
  margin-top: 20px; }

.comments {
  margin-top: 20px; }
  .comments .pagination {
    display: block;
    text-align: center; }
  .comments .comment-rating .help-block {
    font-size: 12px;
    font-style: italic; }
  .comments .comment-rating .ratingfa {
    color: #498bcf; }

.nearby {
  margin-top: 30px; }
  .nearby .ct-personBox-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .nearby .ct-personBox-meta {
    padding-top: 10px; }

.comments .panel-heading,
.nearby .panel-heading {
  font-size: 18px;
  font-weight: bold; }

.place .author .date {
  padding: 4px 0;
  display: inline-block; }

.place div.copyright {
  margin-top: 34px;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 4px; }
  .place div.copyright:after {
    clear: both;
    content: "";
    display: block; }

.place .ct-gallery-item i.copyright {
  position: absolute;
  top: 20px;
  right: 20px; }

.comment-row {
  border-top: 1px solid #e8e8e8;
  padding-top: 10px;
  margin-top: 10px; }
  .comment-row .date {
    font-size: 12px;
    color: grey; }

.place-breadcrumbs {
  position: relative;
  margin-bottom: 30px; }
  .place-breadcrumbs .ct-breadcrumbs {
    bottom: auto;
    top: 0; }

#map-trip {
  border-top: 1px dashed #c0c0c0;
  border-bottom: 1px dashed #c0c0c0;
  width: 100%;
  height: 300px; }

.panel-home-popular .panel-heading {
  padding: 12px 15px 0 15px; }
  .panel-home-popular .panel-heading h2 {
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    margin: 0;
    padding: 10px 0; }

.panel-home-popular .nav-tabs {
  border-bottom: none; }
  .panel-home-popular .nav-tabs li.active {
    z-index: 1000; }

#carousel-home {
  height: 380px;
  overflow: hidden; }
  #carousel-home .item img {
    min-width: 100%; }

#popular .tab-pane.active {
  padding-top: 50px; }

.ct-u-paddingBottom50 {
  padding-bottom: 50px; }

.ct-productBox-imageContainer a {
  display: block; }

.navbar-scroll-top {
  z-index: 1039 !important; }

.ct-breadcrumbs.ct-breadcrumbs--white a, .ct-breadcrumbs.ct-breadcrumbs--white li {
  color: #498bcf; }

.ct-breadcrumbs a {
  color: #ffffff; }
  .ct-breadcrumbs a.sub {
    margin-right: 14px; }

.ct-form-grey select {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
  color: #979797; }

.page-header small {
  display: inline; }

.page-header.noborder {
  border-bottom: none; }

.ct-heading--striped.striped-nolink .ct-right-extension {
  height: 64px; }

.ct-heading--striped.striped-nolink:after {
  height: 26px;
  bottom: -26px; }

.ct-topBar .logout {
  padding-left: 0; }

.ct-productBox-Meta .ct-rating {
  font-size: 14px;
  text-align: right;
  color: #498bcf; }

.ct-productBox .ct-productBox-imageContainer .district {
  border: 1px solid #fff;
  background: #71a5da;
  padding: 10px; }

.ct-productBox .ct-productBox-DescriptionInner .title {
  height: 78px;
  overflow: hidden; }
  .ct-productBox .ct-productBox-DescriptionInner .title a {
    text-overflow: ellipsis; }

.ct-personBox-image.district {
  min-height: 182px; }

.ct-personBox-image img.district {
  margin-top: 30px; }

.ct-personBox-image.ct-productBox-imageContainer .place-author {
  right: 5px; }

.ct-personBox-image .place-author {
  position: absolute;
  top: 5px;
  right: 20px; }
  .ct-personBox-image .place-author.thirty {
    width: 30px;
    height: 30px; }
    .ct-personBox-image .place-author.thirty img {
      width: 100%;
      height: 100%; }
  .ct-personBox-image .place-author.fifty {
    width: 30px;
    height: 30px; }
    .ct-personBox-image .place-author.fifty img {
      width: 100%;
      height: 100%; }
  .ct-personBox-image .place-author.ninety {
    width: 90px;
    height: 90px; }
    .ct-personBox-image .place-author.ninety img {
      width: 100%;
      height: 100%; }
  .ct-personBox-image .place-author.hundred-twenty {
    width: 120px;
    height: 120px; }
    .ct-personBox-image .place-author.hundred-twenty img {
      width: 100%;
      height: 100%; }

.navbar-form {
  margin-top: 28px;
  margin-right: 20px; }
  .navbar-form .form-control {
    border-left: 1px solid #498bcf;
    border-top: 1px solid #498bcf;
    border-bottom: 1px solid #498bcf;
    border-right: none;
    height: 38px; }
    .navbar-form .form-control:focus {
      border-left: 1px solid #498bcf;
      border-top: 1px solid #498bcf;
      border-bottom: 1px solid #498bcf;
      border-right: none; }
  .navbar-form .input-group-btn button {
    border-left: none;
    border-top: 1px solid #498bcf;
    border-bottom: 1px solid #498bcf;
    border-right: 1px solid #498bcf;
    min-width: inherit;
    padding-top: 6px;
    padding-bottom: 6px; }
    .navbar-form .input-group-btn button:hover {
      border-right: 1px solid #498bcf;
      border-top: 1px solid #498bcf;
      border-bottom: 1px solid #498bcf;
      border-left: none; }

.ct-sidebar--search.search-layout input[type=checkbox], .ct-sidebar--search.search-layout label {
  cursor: default; }

.search-result .ct-personBox-title {
  height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px; }

.search-result .ct-personBox-meta {
  height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px; }

.ct-sidebar .widget .widget-inner a {
  padding: 0; }
  .ct-sidebar .widget .widget-inner a i {
    margin-right: 2px; }

.top-list a:hover .badge {
  background-color: #498bcf; }

.fa.online, .fa.offline {
  font-size: 12px; }

.fa.online {
  color: #52c534; }

.fa.offline {
  color: #dadada; }

.ct-topBar .dropdown-menu {
  display: none; }

.ct-topBar .open > .dropdown-menu {
  display: block; }

.navbar-header,
.site-logo {
  font-size: 18px;
  font-family: Lato, sans-serif;
  color: #337ab7; }
  .navbar-header img,
  .site-logo img {
    margin-right: 4px; }
  .navbar-header .slogan,
  .site-logo .slogan {
    font-size: 12px;
    color: #e8e8e8; }

.nav-tab-btn li.active a {
  border-bottom-color: #ddd; }
  .nav-tab-btn li.active a :hover,
  .nav-tab-btn li.active a :focus {
    border-bottom-color: #ddd; }

.modal {
  z-index: 10000; }

footer {
  background: #fafafa; }
  footer .ct-postfooter {
    color: #999999 !important; }

.comments h5 {
  padding: 0 15px; }

.nearby .ct-personBox-image a {
  height: 255px;
  display: block; }

.nearby .ct-personBox-image a img.district {
  padding-top: 12px; }

.ct-iconBox.visited {
  color: #337ab7; }

/** small devices */
@media (max-width: 480px) {
  .nav-tabs li {
    width: auto !important; }
  .rating-layout {
    font-size: 12px; }
  .slick-arrow {
    display: none !important; } }

.form-control.captcha {
  width: auto !important;
  display: inline-block !important; }

.form-control.no-required {
  border-color: #f5f5f5; }

.invited-users {
  clear: both;
  overflow: hidden; }
  .invited-users a {
    width: 50px;
    height: 50px;
    display: block;
    float: left;
    margin-right: 10px;
    position: relative; }
    .invited-users a img {
      display: block;
      width: 100%; }
    .invited-users a .fa {
      position: absolute;
      bottom: 0;
      right: 0; }

.invited-stats {
  margin-top: 20px;
  color: #333333; }
  .invited-stats .stats {
    border-right: 1px solid #d9d9d9; }
    .invited-stats .stats span {
      display: block; }
      .invited-stats .stats span:first-child {
        font-size: 26px; }
      .invited-stats .stats span:last-child {
        color: gray; }
    .invited-stats .stats:last-child {
      border-right: none; }

.trip-location {
  overflow: hidden;
  clear: both; }
  .trip-location .places {
    margin-right: 20px; }

.ct-personBox .ct-personBox-title {
  text-transform: none !important; }

.ct-form-grey input[type=radio] {
  height: auto; }

.comments.trip .comment-row {
  border-top: 0; }

.comments .comment-row .comment-avatar {
  display: block;
  overflow: hidden; }
  .comments .comment-row .comment-avatar span {
    line-height: 48px;
    font-size: 18px; }

.comments .comment-row .time-ago {
  color: #999999;
  font-size: 12px;
  font-weight: normal;
  font-style: italic; }

.comments .comment-row .comment-content {
  position: relative; }
  .comments .comment-row .comment-content .popover {
    max-width: 100%;
    position: relative;
    display: block; }

.comments .comment-row .box {
  border-left: 1px solid #f4f4f4;
  border-right: 1px solid #f4f4f4;
  margin-bottom: 0; }

.nav-default > li.active a:hover,
.nav-default > li.active a:focus,
.nav-default > li.active a:active {
  border-bottom-color: #ddd; }

#trip-map {
  display: none;
  width: 100%;
  height: 500px; }

.show-map-link:after {
  content: "\f0d7";
  font-family: FontAwesome;
  padding-left: 10px; }

.show-map-link.opened:after {
  content: "\f0d8";
  font-family: FontAwesome;
  padding-left: 10px; }

.ct-iconBox.visited .fa {
  color: #337ab7; }

.ct-iconBox.visited .ct-iconBox-description {
  color: #337ab7; }

.head-page h2 {
  padding-left: 15px; }

#header .slogan {
  font-size: 33px;
  line-height: 1em;
  color: #a7bd47;
  font-weight: bold;
  text-align: right;
  padding: 10px 0 0 0; }
  #header .slogan .style1 {
    font-size: 36px;
    color: #ffe50b;
    text-transform: uppercase; }
  #header .slogan .slogan .style2 {
    font-size: 17px;
    text-transform: uppercase; }
  #header .slogan .style3 {
    color: #fff; }
  #header .slogan .style4 {
    font-size: 27px;
    text-transform: uppercase; }
  #header .slogan .style5 {
    font-size: 51px;
    line-height: .85em;
    text-transform: uppercase;
    color: #ffe50b;
    font-weight: normal; }

.navbar.no-space {
  margin-bottom: 0; }

#navbar li.language {
  line-height: 50px; }
  #navbar li.language a {
    background: #46a149;
    display: inline;
    padding: 6px 12px;
    border-radius: 4px;
    text-transform: lowercase; }
    #navbar li.language a:hover {
      background: #2d682f;
      color: #fff; }

.rating-layout .ratingfa {
  display: inline-block;
  color: #498bcf; }

.content {
  font-size: 18px; }

.footer {
  width: 100%;
  margin-top: 60px;
  padding-top: 20px; }
  .footer.map {
    margin-top: 0px; }
  .footer .copyright {
    padding-top: 10px;
    text-align: center; }
  .footer ul {
    list-style: none;
    padding-left: 0; }

.share {
  margin: 10px 0; }
  .share .fb_iframe_widget {
    bottom: 7px;
    margin-right: 10px; }

#map-canvas {
  width: 100%;
  height: 800px;
  margin: 0px;
  padding: 0px; }

footer .text-muted {
  margin: 20px 0; }

.trip-box {
  border: 1px solid #c9c9c9; }

.popular-filter {
  padding: 2px 10px;
  height: auto;
  line-height: normal; }

.preloader {
  text-align: center;
  height: 100px;
  line-height: 100px; }

#suggest-place-map {
  height: 400px; }

.provile_avatar {
  display: block;
  float: left;
  width: 30px;
  height: 30px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  margin: 0px 10px 8px 0px;
  position: relative;
  box-shadow: inset 0px 0px 1px rgba(0, 0, 0, 0.6); }

.users span {
  display: block;
  margin-bottom: 10px; }

.avatar_circle {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50% 50%;
  float: left;
  margin-right: 6px;
  text-align: center;
  text-decoration: none; }
  .avatar_circle img, .avatar_circle em {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50% 50%; }
  .avatar_circle img {
    border: 1px solid #cecece; }
  .avatar_circle em {
    text-decoration: none;
    background: #eeeeee;
    font-style: normal;
    line-height: 50px;
    color: #03273d;
    font-family: Arial; }

#infscr-loading > div {
  display: none; }

.ct-slick--arrowsCustom .slick-next, .ct-slick--arrowsCustom .slick-prev {
  background-color: #498bcf !important; }
  .ct-slick--arrowsCustom .slick-next:hover, .ct-slick--arrowsCustom .slick-prev:hover {
    background-color: #498bcf; }

/*# sourceMappingURL=app.css.map */
