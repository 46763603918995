
.primary-bg {
    background: #333333 !important;
    color: white;
}

.primary-bg:hover {
    background: #1a1a1a !important;
}

.success-bg {
    background: #76bbad !important;
    color: white;
}

.success-bg:hover {
    background: #54aa98 !important;
}

.danger-bg {
    color: white;
    background: #d66061 !important;
}

.danger-bg:hover {
    background: #cc3739 !important;
}

.info-bg {
    color: white;
    background: #5b90bf !important;
}

.info-bg:hover {
    background: #4177a6 !important;
}

.warning-bg {
    color: white;
    background: #ffaa3a !important;
}

.warning-bg:hover {
    background: #ff9407 !important;
}

.fb-bg {
    background: #4c66a4;
    color: white;
}

.fb-bg:hover {
    background: #3c5081 !important;
}

.twitter-bg {
    background: #00acee;
    color: white;
}

.twitter-bg:hover {
    background: #0087bb !important;
}

.linkedin-bg {
    background: #1a85bd;
    color: white;
}

.linkedin-bg:hover {
    background: #146590 !important;
}

.gplus-bg {
    background: #dc4937;
    color: white;
}

.gplus-bg:hover {
    background: #bf3321 !important;
}

.brown-bg {
    background: #ab7967;
    color: white;
}

.brown-bg:hover {
    background: #90604f !important;
}

.grey-bg {
    background: #666666 !important;
    color: #666666;
}

.grey-bg:hover {
    background: #595959 !important;
}

.light-grey-bg {
    background: #ecf0f5 !important;
    color: #666666;
}

.panel {
    margin-bottom: 25px;
    background-color: white;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.panel-body {
    padding: 15px;
    position: relative;
    border: 1px solid #d9d9d9;
}

.panel-heading {
    position: relative;
    padding: 12px 15px;
    border: 1px solid #d9d9d9;
    border-bottom: 0;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
}

.panel-heading .custom-icon {
    position: absolute;
    right: 15px;
    top: 12px;
    color: #333333;
    font-size: 14px;
}

.panel-heading .custom-text {
    position: absolute;
    right: 15px;
    top: 12px;
    color: #333333;
    font-size: 12px;
}

h4.panel-title {
    font-size: 15px;
    font-weight: 600;
    color: #4d4d4d;
}

h4.panel-title .fa {
    margin-right: 4px;
    font-size: 14px;
    width: 20px;
}

h3.panel-title {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    color: #333333;
}

.panel-footer {
    padding: 0;
    border: 0;
    margin-top: -1px;
    background: transparent;
    border: 1px solid #737373;
    border-top: 0;
}

.panel-default {
    border: 1px solid #e8e8e8;
}

.panel-default > .panel-heading {
    color: #595959;
    border-color: #e8e8e8;
    background: #fcfcfc;
}

.panel-default > .panel-footer {
    border-color: #e8e8e8;
    background: #f7f7f7;
}

.panel-default > .panel-footer a {
    color: #666666;
}

.panel-default > .panel-footer a:hover {
    color: #4d4d4d;
}

.timeline {
    list-style: none;
    position: relative;
    max-width: 1000px;
    padding: 20px 10px;
    margin: 0 auto;
    overflow: hidden;
}

.timeline:after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -2px;
    background-color: #d9d9d9;
    height: 100%;
    width: 1px;
    display: block;
}

.timeline .timeline-row {
    padding-left: 50%;
    position: relative;
    z-index: 10;
}

.timeline .timeline-row .timeline-time {
    position: absolute;
    right: 50%;
    top: 31px;
    text-align: right;
    margin-right: 40px;
    font-size: 16px;
    line-height: 1.3;
    font-weight: 600;
}

.timeline .timeline-row .timeline-time small {
    display: block;
    color: #76bbad;
    font-size: 11px;
    font-weight: 600;
}

.timeline .timeline-row .timeline-icon {
    position: absolute;
    top: 30px;
    left: 50%;
    margin-left: -20px;
    width: 38px;
    height: 38px;
    background-color: #d1d1d1;
    text-align: center;
    overflow: hidden;
    padding: 2px;
    color: white;
    font-size: 14px;
    z-index: 100;
}

.timeline .timeline-row .timeline-icon > div {
    line-height: 34px;
    font-size: 16px;
}

.timeline .timeline-row .timeline-content {
    margin-left: 40px;
    position: relative;
    background-color: white;
    color: #333333;
}

.timeline .timeline-row .timeline-content:after {
    content: "";
    position: absolute;
    top: 48px;
    left: -41px;
    height: 1px;
    width: 40px;
    background-color: #d9d9d9;
    z-index: -1;
}

.timeline .timeline-row .timeline-content .panel-body {
    padding: 15px;
    position: relative;
    z-index: 10;
}

.timeline .timeline-row .timeline-content h2 {
    font-size: 22px;
    margin-bottom: 12px;
    margin-top: 0;
    line-height: 1.2;
}

.timeline .timeline-row .timeline-content p, .timeline .timeline-row .timeline-content img {
    margin-bottom: 15px;
}

.timeline .timeline-row:nth-child(odd) {
    padding-left: 0;
    padding-right: 50%;
}

.timeline .timeline-row:nth-child(odd) .timeline-time {
    right: auto;
    left: 50%;
    text-align: left;
    margin-right: 0;
    margin-left: 30px;
}

.timeline .timeline-row:nth-child(odd) .timeline-content {
    margin-right: 40px;
    margin-left: 0;
}

.timeline .timeline-row:nth-child(odd) .timeline-content:after {
    left: auto;
    right: -41px;
}

.timeline.show-animated .timeline-row .timeline-content {
    opacity: 0;
    left: 20px;
    -webkit-transition: all 0.8s;
    -moz-transition: all 0.8s;
    transition: all 0.8s;
}

.timeline.show-animated .timeline-row:nth-child(odd) .timeline-content {
    left: -20px;
}

.timeline.show-animated .timeline-row.active .timeline-content {
    opacity: 1;
    left: 0;
}

.timeline.show-animated .timeline-row.active:nth-child(odd) .timeline-content {
    left: 0;
}

.timeline-row.end{
    padding: 0 !important;
    text-align: center;
    margin-top: 6px;
    margin-bottom: 26px;
}
.timeline-row.end .end{
    background: #f0f0f0;
    border: 1px solid #d9d9d9;
    color: #000;
    font-size: 20px;
    padding: 10px;
}

@media (max-width: 1200px) {
    .timeline {
        padding: 15px 10px;
    }

    .timeline:after {
        left: 28px;
    }

    .timeline .timeline-row {
        padding-left: 0;
        margin-bottom: 16px;
    }

    .timeline .timeline-row .timeline-time {
        position: relative;
        right: auto;
        top: 0;
        text-align: left;
        margin: 0 0 6px 56px;
    }

    .timeline .timeline-row .timeline-time strong {
        display: inline-block;
        margin-right: 10px;
    }

    .timeline .timeline-row .timeline-icon {
        top: 52px;
        left: -2px;
        margin-left: 0;
    }

    .timeline .timeline-row .timeline-content {
        margin-left: 56px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        position: relative;
    }

    .timeline .timeline-row .timeline-content:after {
        right: auto !important;
        left: -20px !important;
        top: 32px;
    }

    .timeline .timeline-row:nth-child(odd) {
        padding-right: 0;
    }

    .timeline .timeline-row:nth-child(odd) .timeline-time {
        position: relative;
        right: auto;
        left: auto;
        top: 0;
        text-align: left;
        margin: 0 0 6px 56px;
    }

    .timeline .timeline-row:nth-child(odd) .timeline-content {
        margin-right: 0;
        margin-left: 55px;
    }

    .timeline.show-animated .timeline-row:nth-child(odd) .timeline-content {
        left: 20px;
    }

    .timeline.show-animated .timeline-row.active:nth-child(odd) .timeline-content {
        left: 0;
    }
}